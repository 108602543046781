<template>
  <main class="login">
    <div class="login-container page-container container">
      <div class="login-box resetPassword">
        <h3 class="login-title">Reset Password</h3>
        <form class="login-form">
          <div class="form-group">
            <label for="user_login" class="login-form-label"
              >Enter your user account's verified email address and we will send
              you a password reset link.</label
            >
            <input
              type="email"
              class="form-control was-validated"
              :class="{ 'is-invalid': errorMsg.email }"
              v-model.trim.lazy="email"
              required
              autofocus
            />
            <div class="invalid-feedback" v-show="errorMsg.email">
              {{ errorMsg.email }}
            </div>
          </div>
          <div class="form-group login-form-reset">
            <a class="btn btn-link" @click="$router.go(-1)">
              <span class="material-icons">chevron_left</span> go back</a
            >
          </div>
          <button
            type="submit"
            class="login-form-submit btn btn-primary"
            @click.prevent="sendEmail"
          >
            Send password reset email
          </button>
          <div
            class="invalid-feedback"
            style="display:block;"
            v-show="errorMsg.all"
          >
            {{ errorMsg.all }}
          </div>
        </form>
      </div>
      <div class="login-bottomBg">
        <img :src="`${publicPath}img/login-bg.jpg`" alt="" />
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PasswordSetup',
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      email: '',
      errorMsg: {
        email: '',
        all: ''
      }
    }
  },
  methods: {
    ...mapActions(['logout', 'resetPassword']),
    validationEmail(value) {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const result = regex.test(String(value).toLowerCase())
      if (result) {
        this.errorMsg.email = ''
      } else {
        this.errorMsg.email = 'Please provide a valid email.'
      }
      return result
    },
    async sendEmail() {
      this.errorMsg.all = ''
      let validForm = this.validationEmail(this.email)
      if (validForm) {
        await this.resetPassword({ email: this.email })

        this.logoutHandler()
      }
    },
    async logoutHandler() {
      let status = await this.logout()
      if (status === 200) {
        return this.$router.push('/pages/login')
      } else {
        return this.$router.push('/pages/page404')
      }
    }
  }
}
</script>
